import React from 'react';
import Api from '../../../api.js';
import _ from 'lodash';

class Grid extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pubRoots: props.pubRoots,
      landing: null,
      breadcrumb: props.breadcrumb,
      selectedToc: props.selectedToc
    };
    this.api = new Api();
    this.env = this.api.getEnv();
    this.domain = this.api.getDomain();
    this.subdomain = this.api.getSubdomain();
    this.queue = [];
  }

  componentDidMount = () => {
    if (this.state.pubRoots.length) {
      this.generateLanding(this.state.pubRoots, this.props.breadcrumb, this.props.selectedToc);
    }
  }

  componentDidUpdate = () => {
    const pubRoots = this.props.pubRoots;


    if (this.state.breadcrumb !== this.props.breadcrumb) {
      this.setState({ selectedCategory: this.props.selectedCategory, breadcrumb: this.props.breadcrumb });
      this.generateLanding(pubRoots, this.props.selectedToc);

    } else if (!this.isEqual(this.state.pubRoots, pubRoots)) {
      this.setState({ pubRoots: [...pubRoots], breadcrumb: this.props.breadcrumb });
      this.generateLanding(pubRoots, this.props.selectedToc);

    }

  }
  isEqual = (arr1, arr2) => {
    if (arr1 === null && arr1 === arr2) {
      return true;
    }
    if (arr1 === null && arr1 !== arr2) {
      return false;
    }
    if (arr2 === null && arr1 !== arr2) {
      return false;
    }
    if (arr1.length !== arr2.length) return false;
    else if (arr1.length === 0) return true;
    else {
      if (arr1[0].rootNode.element.id.data.exhibitionId === arr2[0].rootNode.element.id.data.exhibitionId) {
        return true;
      } else {
        return false;
      }
    }
  }
  isEqual2 = (arr1, arr2) => {
    if (arr1 === null && arr1 === arr2) {
      return true;
    }
    if (arr1 === null && arr1 !== arr2) {
      return false;
    }
    if (arr2 === null && arr1 !== arr2) {
      return false;
    }
    if (arr1.length !== arr2.length) return false;
    else if (arr1.length === 0) return true;
    else {
      let notEqual = false;
      arr1.forEach((e, i) => notEqual = notEqual === true ? true : arr2[i] !== arr1[i]);
      return !notEqual;
    }
  }

  writeBreadCrumb = (title, url) => {
    return <a href={window.location.origin + "/search" + url}>{title}</a>
  }

  drawTile = (item, title, url) => {
    const element = item.element;
    const children = item.children;
    const coverInfo = this.getTileInfo(children);
    let bgImage, cname;
    if (coverInfo.video) {
      bgImage = "url(yt.png?1234), url(https://i.ytimg.com/vi/" + coverInfo.video + "/maxresdefault.jpg)";
      cname = "tile article yt";
    } else if (coverInfo.image) {
      bgImage = "url(https://api.clay.work/download/" + coverInfo.image + "?Tenant=clay&qualifier=presentation)";
      cname = "tile article";
    } else if (coverInfo.audio) {
      bgImage = "url(https://cdn-icons-png.flaticon.com/512/7412/7412958.png)";
      cname = "tile article podcast";

    }
    const cover = <div key={element.id.data.valueCellId} className={cname}
      style={{ backgroundImage: bgImage }}>
      <div className='header'>
        <b><span>
          {coverInfo.name}</span></b>
      </div>
    </div>
    return cover;
  }

  drawLinkedTile = (item, url) => {
    const element = item.element;
    const children = item.children;
    const coverInfo = this.getTileInfo(children);
    let bgImage, cname;
    if (coverInfo.video) {
      bgImage = "url(https://i.ytimg.com/vi/" + coverInfo.video + "/maxresdefault.jpg)";
      cname = "tile article yt";
    } else {
      bgImage = "url(https://api.clay.work/download/" + coverInfo.image + "?Tenant=clay&qualifier=presentation)";
      cname = "tile article";
    }
    const cover = <a className={cname} href={window.location.origin + "/search" + url}>
      <div key={element.id.data.valueCellId} className="poster"
        style={{ backgroundImage: bgImage }}>
        <div className='header'>
          <b><span>
            {coverInfo.name}</span></b>
        </div>
      </div>
    </a>
    return cover;
  }

  openArticle = (aId, eId) => {
    this.props.selectArticle([aId], eId);
  }

  getTileInfo = (children) => {
    let name, image, video, audio;
    children.forEach(ch => {
      if (ch.element.content.data.attribute === "name") {
        name = ch.element.content.data.value.name;
      } else if (ch.element.contentType.type === "Section") {
        ch.children.forEach(child => {
          if (!image && (child.element.content.data.attribute === "image")) {
            image = child.element.content.data.value.href;
          } else if (!video && child.element.content.data.attribute === "youtube") {
            video = child.element.content.data.value.id;
          } else if (!video && !image && child.element.content.data.attribute === "document") {
            // https://api.clay.work/download/resource:audio/vnd.wave,user-baafa143-209f-44d3-b7ce-b08718f79dda?Authentication-Token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJiMjMyMTQ1OC05MTAwLTQ2ZjgtODRkNS05NzEzNjAzMTljYTQiLCJpYXQiOjE3MTc1ODU2OTksImV4cCI6MTcyMDAwNDg5OSwiYWNjb3VudElkIjoiYjczYmNhNDEtOTQ4OS00MDJmLWFkYzAtOTU4YjViMzMzYjRjIiwicm9sZXMiOltdLCJzdGF0dXMiOiJBY3RpdmUifQ.8zyZNTAtWRjZYrOHbxzuZcHxCz0hzSY9RC8UMhV_PGU&Tenant=clay&qualifier=presentation
            audio = child.element.content.data.value.href;
          }
        })
      }
    })
    return {
      name: name, image: image, video: video, audio: audio
    };
  }

  processSelection = (child, eId, toc, catPage = false, parentUrl) => {
    let url = parentUrl;
    if (catPage === false) {
      url = "/" + toc.url;
    }
    if (toc.item.data.classification) {
      const articles = toc.children.filter(child => child.item.type === "Content");
      const subCats = toc.children.filter(child => child.item.type === "Classification");
      let catCover = [];

      if (articles.length) {
        const tileArray = toc.children.map((tocItem, i) => {
          const thisArt = child.children.find(c => c.element.id.data.valueCellId === tocItem.item.data.contentId?.data.valueCellId)
          let cover = '';
          if (thisArt) {
            if (catPage) {
              cover = this.drawLinkedTile(thisArt, url + "/" + tocItem.url);
            } else {
              cover = this.drawTile(thisArt, tocItem.item.data.title, url + "/" + tocItem.url);
            }
          }
          return cover;
        }).filter(i => i !== '');
        if (catPage) {
          catCover = tileArray;
        } else {
          catCover = [<div className='tile category' ><a href={window.location.origin + "/search/" + toc.url}>
            <div className='header cat-header'><b>
              <span> {toc.item.data.classification}</span>
            </b></div>{_.take(tileArray, 5)}
          </a>
          </div>]
        }
      }
      if (subCats.length > 0) {
        const catTiles = subCats.map(subCat => {
          const artSlivers = subCat.children.map((sc, i) => {
            const thisArt = child.children.find(c => c.element.id.data.valueCellId === sc.item.data.contentId?.data.valueCellId)
            if (thisArt) {
              const cover = this.drawTile(thisArt, url + "/" + toc.url + "/" + sc.url);
              return cover;
            } else {
              return '';
            }
          });
          let bc = "";
          if (!catPage) {
            bc = toc.item.data.classification;
          }
          return <div className='tile category'>
            <a href={window.location.origin + "/search" + url + "/" + subCat.url}><div className='header cat-header'><b>
              <span> {subCat.item.data.classification}</span>
            </b></div>{_.take(artSlivers, 5)}</a></div>
        })
        catCover.push(catTiles)
      }
      return catCover;
    }
  }

  getContentForTocItem = (root, category) => {
    const content = root.children.filter(ch => {
      return ch.children.filter(chil => {
        return chil.element.classifications.indexOf(category.item.data.classification) > -1
      }).length > 0;
    });
    if (content.length > 1) {
      const surfaceContent = content.filter(ch => {
        return ch.children[0].element.classifications.includes(category.item.data.classification);
      })
      return surfaceContent;
    } else {
      return content
    }
  }

  generateLanding = (pubRoots, selectedToc, url = this.props.url) => {
    if (selectedToc) {
      const eId = pubRoots[0].rootNode.element.id.data.exhibitionId;
      const content = this.getContentForTocItem(pubRoots[0].rootNode, selectedToc);
      this.setState({
        landing: this.processSelection(content[0], eId, selectedToc, true, url)
      })
    }
    else {
      this.setState({
        landing: pubRoots.map(item => {
          const eId = item.rootNode.element.id.data.exhibitionId;
          const categories = item.tableOfContents;
          const tileArrays = categories.map((category, i) => {

            const content = this.getContentForTocItem(item.rootNode, category);
            return this.processSelection(content[0], eId, category, false, url)
          })
          return tileArrays;
        })
      })
    }
  }

  searchContext = (children, cId) => {
    this.queue.push(...children);
    while (this.queue.length > 0) {
      const child = this.queue.shift();
      if (child.element.id.data.valueCellId === cId) {
        this.searchResult = child;
        this.queue.length = 0;
      } else {
        this.searchContext(child.children, cId);
      }
    }
  }

  selectArticle = (cId) => {
    this.queue = [];
    this.searchResult = null;
    this.searchContext(this.props.pubRoots[0].rootNode.children, cId);
    return this.searchResult;
  }

  render() {
    return (
      <div className={`board-container landing-page grid`}>
        {this.state.landing}
        <hr style={{ width: "100%", visibility: "hidden" }} />
        {/* <TMFooter /> */}
      </div>
    )
  }
}

export default Grid;